import React from 'react'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import Footer from '@components/Footer'
import TitleContent from '@components/TitleContent'
import RichFeaturedLinks from '@components/RichFeaturedLinks'
import List from '@components/Articles/List'
import { getTagsFromItems } from '@utils'

const NewsPage = ({ data, location }) => {
  const { slug, seo, intro, richFeaturedNews } = data.contentfulNews

  let tags = getTagsFromItems(data.allContentfulNewsArticle.nodes, 'News:')

  return (
    <>
      <Seo data={seo} slug={slug} />
      <TitleContent
        title={intro.title}
        content={intro.content}
        alignment={intro.alignmentDesktop}
      />
      <RichFeaturedLinks blocks={richFeaturedNews} />
      <List
        tags={tags}
        articles={data.allContentfulNewsArticle.nodes}
        linkLabel="Read Story"
        types="News"
      />
      <Footer location={location} />
    </>
  )
}

export default NewsPage

// Query for marketing tools sorted by pinned and then by last updated
export const pageQuery = graphql`
  query NewsPage {
    contentfulNews {
      slug
      seo {
        ...SEO
      }
      intro {
        ...TitleContentBlock
      }
      richFeaturedNews {
        ...ImageContentBlock
      }
    }
    allContentfulNewsArticle(
      sort: { fields: [pinned, createdAt], order: [DESC, DESC] }
    ) {
      nodes {
        contentful_id
        createdAt
        internal {
          type
        }
        metadata {
          tags {
            name
          }
        }
        pinned
        title
        slug
        excerpt
        introImage {
          gatsbyImageData(aspectRatio: 1.6)
          description
        }
        thumbnail {
          gatsbyImageData(aspectRatio: 1.6)
          description
        }
      }
    }
  }
`
